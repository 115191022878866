var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"display":"none"},attrs:{"id":"printMe"}},[(_vm.currentUser.distributorType === 'wholesaler')?_c('div',{attrs:{"id":"print"}},[_c('pdf-contract',{style:(_vm.status === 'print'
            ? 'min-width:100% !important;min-height:100% !important'
            : ''),attrs:{"id":"page1","record":_vm.record}}),_c('pdf-contract-page2',{style:(_vm.status === 'print'
            ? 'min-width:100% !important;min-height:100% !important'
            : ''),attrs:{"id":"page2"}}),_c('pdf-contract-page3',{style:(_vm.status === 'print'
            ? 'min-width:100% !important;min-height:100% !important'
            : ''),attrs:{"show":true,"userSignature":_vm.signature,"signature":_vm.settings ? _vm.settings.signature : '',"id":"page3","record":_vm.record}})],1):_c('div',{attrs:{"id":"print"}},[_c('pdf-contract-retailer',{style:(_vm.status === 'print'
            ? 'min-width:100%;min-height:100%'
            : ''),attrs:{"id":"page1","wholesaller":_vm.wholeSellerRecord,"record":_vm.record}}),_c('pdf-contract-page2-retailer',{style:(_vm.status === 'print'
            ? 'min-width:100%;min-height:100%'
            : ''),attrs:{"wholesaller":_vm.wholeSellerRecord,"id":"page2"}}),_c('pdf-contract-page3-retailer',{style:(_vm.status === 'print'
            ? 'min-width:100%;min-height:100%'
            : ''),attrs:{"show":true,"userSignature":_vm.signature,"signature":_vm.wholeSellerRecord ? _vm.wholeSellerRecord.signature : '',"wholesaller":_vm.wholeSellerRecord,"id":"page3","record":_vm.record}})],1)]),_c('div',{staticClass:"row justify-center app-content-page"},[_c('div',{staticClass:"col-lg-6 col-md-8 col-sm-10 col-xs-12"},[_c('lottie-player',{staticClass:"lottie",attrs:{"src":"https://assets10.lottiefiles.com/packages/lf20_dsdnq1nx.json","background":"transparent","speed":"1","loop":"","autoplay":""}})],1),_c('div',{staticClass:"col-12 q-mt-xl"},[_c('div',{staticClass:"row justify-center"},[_c('div',{staticClass:"col-lg-3 col-md-4 col-sm-5 col-xs-5 q-pa-xs justify-center",staticStyle:{"height":"fit-content"}},[_c('q-btn',{staticClass:"btn continue_btn weight-bold radius-10 full-width",attrs:{"flat":"","no-caps":"","id":"sign-in-button","label":_vm.i18n('common.print'),"text-color":"white"},on:{"click":_vm.approve},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('q-spinner-ios',{attrs:{"size":"sm"}})]},proxy:true}])})],1),_c('div',{staticClass:"col-lg-3 col-md-4 col-sm-5 col-xs-5 q-pa-xs justify-center",staticStyle:{"height":"fit-content"}},[_c('q-btn',{staticClass:"btn ignore_btn weight-bold radius-10 full-width",attrs:{"flat":"","no-caps":"","id":"sign-in-button","label":_vm.i18n('auth.ignore')},on:{"click":_vm.ignore},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('q-spinner-ios',{attrs:{"size":"sm"}})]},proxy:true}])})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }