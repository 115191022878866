//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import contractRetailer from '@/modules/PDF/components/retailer/contract';
import contractPage2Retailer from '@/modules/PDF/components/retailer/contractPage2';
import contractPage3Retailer from '@/modules/PDF/components/retailer/contractPage3';

import contract from '@/modules/PDF/components/contract';
import contractPage2 from '@/modules/PDF/components/contractPage2';
import contractPage3 from '@/modules/PDF/components/contractPage3';

import { routerAsync } from '@/app-module';
import { mapActions, mapGetters } from 'vuex';
import firebase from 'firebase';
import 'firebase/firestore'
import { i18n, getLanguageCode } from '@/i18n';
export default {
  data() {
    return {
      page: 1,
      step: 1,
      status: 'print',
      confirm: false,
      signature: null,
      sigChanged: false,
      seller: {},
      isContinue: false,
      accept: false,
      language: getLanguageCode(),
    };
  },
  created() {
    if (
      !this.currentUser.status ||
      this.currentUser.status !== 'confirmed'
    ) {
      routerAsync().push('/contract');
    }
    this.getCityAndRegion();
    this.doFind();
    this.getSignature();
  },
  watch: {
    currentUser(newVal) {
      if (newVal) {
        if (
          newVal.distributorType === 'Retailer' &&
          newVal.wholesalerId
        ) {
          this.getSaller(newVal.wholesalerId);
        }
      }
    },
  },
  methods: {
    ...mapActions({
      getCityAndRegion: 'auth/getCityAndRegion',
      doSignout: 'auth/doSignout',
      doApprove: 'contract/doApprove',
      doFind: 'settings/doFind',
      getSaller: 'auth/getSaller',
    }),
    addSignature(data) {
      this.signature = data;
      this.sigChanged = true;
      this.confirm = false;
      this.status = 'print';
      let doc = document.querySelector('#print').innerHTML;
      this.doApprove(doc);
      return (this.step = 1);
    },
    async getSignature() {
      let docs = (
        await firebase
          .firestore()
          .collection('tempSeller')
          .where(
            'phoneNumber',
            '==',
            this.currentUser.phoneNumber,
          )
          .get()
      ).docs;
      if (docs.length) {
        this.seller = docs[0].data();
        this.signature = this.seller.signature;
      }
    },
    i18n(label) {
      return i18n(label);
    },
    i18nArg(label, arg) {
      return i18n(label, arg);
    },
    approve() {
      this.$htmlToPaper('print', null, () => {
        this.status = null;
      });
    },
    ignore() {
      // this.doSignout();
      routerAsync().push('/contract');
    },
  },
  mounted() {
    this.getSaller(this.currentUser.wholesalerId);
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      city: 'auth/city',
      region: 'auth/region',
      settings: 'settings/settings',
      wholesaller: 'auth/wholesaller',
      is_screen_sm: 'layout/is_screen_sm',
    }),
    isConfirmed() {
      return this.currentUser
        ? this.currentUser.contractConfirmed
        : false;
    },
    record() {
      let c = this.city.find(
        (I) => I.id === this.currentUser.cityId,
      ) || { name: {} };
      let r = this.region.find(
        (I) =>
          I.name.ar === this.currentUser.regionId ||
          I.name.en === this.currentUser.regionId,
      ) || { name: {} };
      return {
        name: this.currentUser.fullName,
        city: c.name['ar'],
        taxNumber: this.currentUser.taxFacilityNumber,
        idNumber: this.currentUser.idNumber,
        region: r.name['ar'],
        address: this.currentUser.address,
        company: this.currentUser.company,
        area: this.currentUser.area,
        distributorType: this.currentUser.distributorType,
        signature: this.currentUser.signature
      };
    },
    wholeSellerRecord() {
      let c = this.city.find(
        (I) => I.id === this.wholesaller.cityId,
      ) || { name: {} };
      let r = this.region.find(
        (I) =>
          I.name.ar === this.wholesaller.regionId ||
          I.name.en === this.wholesaller.regionId,
      ) || { name: {} };
      return {
        name: this.wholesaller.name,
        city: c.name['ar'],
        taxNumber: this.wholesaller.taxFacilityNumber,
        idNumber: this.wholesaller.idNumber,
        region: r.name['ar'],
        address: this.wholesaller.address,
        company: this.wholesaller.company,
        area: this.wholesaller.area,
        distributorType: this.wholesaller.distributorType,
        signature: this.wholesaller.signature
      };
    },
  },
  components: {
    [contract.name]: contract,
    [contractPage2.name]: contractPage2,
    [contractPage3.name]: contractPage3,
    [contractRetailer.name]: contractRetailer,
    [contractPage2Retailer.name]: contractPage2Retailer,
    [contractPage3Retailer.name]: contractPage3Retailer,
  },
};
